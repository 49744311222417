<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row">
                <div class="tw-space-y-1 col-md-12">
                    <label>{{ $t("bookingPickup.voucherNumber") }}</label>
                    <input
                        v-model.trim="model.voucher_no"
                        type="text"
                        class="form-control"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('voucher_no')"
                    >
                        {{ errors.first("voucher_no") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="tw-space-y-1 col-md-12">
                    <label class="required">{{
                        $t("bookingPickup.serviceType")
                    }}</label>
                    <a-select
                        v-model="model.service_type_id"
                        :options="serviceType"
                        class="tw-w-full"
                        :placeholder="$t('bookingPickup.serviceType')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('service_type_id')"
                    >
                        {{ errors.first("service_type_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="tw-space-y-1 col-md-12">
                    <label class="required">{{
                        $t("bookingPickup.paidBy")
                    }}</label>
                    <a-select
                        v-model.number="model.fee_paid_by"
                        :placeholder="$t('bookingPickup.paidBy')"
                        :options="[
                            { value: 1, label: 'Buyer' },
                            { value: 2, label: 'Shop' }
                        ]"
                        class="tw-w-full"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('fee_paid_by')"
                    >
                        {{ errors.first("fee_paid_by") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="tw-space-y-1 col-md-12">
                    <label>{{
                        $t("bookingPickup.comment")
                    }}</label>
                    <textarea
											v-model.trim="model.confirmed_comment" 
											class="form-control" rows="3"></textarea>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('confirmed_comment')"
                    >
                        {{ errors.first("confirmed_comment") }}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        @click.prevent="$emit('cancel')"
                        :disabled="loading"
                        >{{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        @click.prevent="onSave"
                        :disabled="loading"
                        >{{ $t("save") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";
export default {
    name: "formBookingPickup",
    props: ["packaging"],
    data() {
        return {
            loading: false,
            errors: new Errors(),
            model: {
                packaging_id: "",
                voucher_no: "",
                confirmed_comment: "",
                service_type_id: "",
                fee_paid_by: 2
            }
        };
    },
    computed: {
        ...mapState("inventory/productPackaging", ["formModels"]),
        serviceType() {
            if (!this.formModels.serviceType) return [];
            return this.formModels.serviceType.map(el => ({
                value: el.service_type_id,
                label: el.service_type_name_en
            }));
        }
    },
    created() {
        this.fetchFormView({
            params: {
                fnName: "serviceType"
            },
            index: "serviceType"
        });
    },
    methods: {
        ...mapActions("inventory/productPackaging", [
            "getFormViewData",
            "convertBooking"
        ]),
        fetchFormView({ params, index }) {
            this.loading = true;
            this.getFormViewData({ params: params, index: index })
                .then(() => {
                    this.setDataFromPackaging();
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.loading = true;
            this.convertBooking(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setDataFromPackaging() {
            if (!isEmpty(this.packaging)) {
                this.model.packaging_id = this.packaging.packaging_id;
                this.model.fee_paid_by = 2;
            }
        },
        numberPackageChange() {
            this.model.packaging_fee =
                this.feePerPage * this.model.number_of_package;
        }
    }
};
</script>
