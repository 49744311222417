<template>
  <div>
    <textarea
      v-model="model.deleted_reason"
      :placeholder="placeholder"
      rows="3"
      class="form-control"
      :class="{ 'is-invalid': errors.has('deleted_reason') }"
    ></textarea>
    <div class="invalid-feedback" v-if="errors.has('deleted_reason')">
      {{ errors.first('deleted_reason') }}
    </div>
  </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
export default {
  name: 'reasonDelete',
  props: ['value', 'validate', 'placeholder'],
  data() {
    return {
      model: {},
      errors: new Errors()
    }
  },
  created() {
    this.model = this.value
  },
  methods: {},
  watch: {
    validate: function(error) {
      this.errors = new Errors(error.errors)
    }
  }
}
</script>
