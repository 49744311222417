<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row">
                <div class="col-md-12">
                    <h5>{{ $t("productPackaging.packageInfo") }}</h5>
                    <div class="tw-border tw-border-gray-200 tw-p-3 tw-rounded">
                        <div class="tw-grid tw-grid-cols-2 tw-gap-3">
                            <div>
                                {{ $t("productPackaging.shopName") + ": " }}
                                <b>{{ record.shop_name }}</b>
                            </div>
                            <div>
                                {{
                                    $t("productPackaging.packagingNumber") +
                                        ": "
                                }}
                                <b>{{ record.packaging_number }}</b>
                            </div>
                            <div>
                                {{ $t("productPackaging.receiverName") + ": " }}
                                <b>{{ record.receiver_name }}</b>
                            </div>
                            <div>
                                {{
                                    $t("productPackaging.receiverPhone") + ": "
                                }}
                                <b>{{ record.receiver_phone }}</b>
                            </div>
                            <div class="tw-col-span-2">
                                {{
                                    $t("productPackaging.receiverLocation") +
                                        ": "
                                }}
                                <b>{{ record.receiver_location }}</b>
                            </div>
                        </div>
                        <div
                            class="tw-overflow-hidden tw-mt-3 tw-rounded"
                            style="max-height:250px"
                        >
                            <table class="tw-w-full">
                                <thead>
                                    <tr>
                                        <th
                                            class="tw-uppercase tw-bg-gray-200 tw-px-2"
                                        >
                                            {{ $t("no") }}
                                        </th>
                                        <th
                                            class="tw-uppercase tw-bg-gray-200 tw-px-2"
                                        >
                                            {{
                                                $t(
                                                    "productPackaging.productName"
                                                )
                                            }}
                                        </th>
                                        <th
                                            class="tw-uppercase tw-bg-gray-200 tw-px-2"
                                        >
                                            {{ $t("productPackaging.uom") }}
                                        </th>
                                        <th
                                            class="tw-uppercase tw-bg-gray-200 tw-px-2 tw-text-center"
                                        >
                                            {{ $t("productPackaging.qty") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(d, index) in record.details"
                                        :key="index"
                                    >
                                        <td
                                            class="tw-px-2 tw-text-center tw-w-8"
                                        >
                                            {{ index + 1 }}
                                        </td>
                                        <td class="tw-px-2">
                                            {{ d.product_name_en }}
                                        </td>
                                        <td class="tw-px-2">
                                            {{ d.uom_name_en }}
                                        </td>
                                        <td class="tw-px-2 tw-text-center">
                                            {{ d.quantity }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="tw-space-y-1 col-md-12">
                    <label>{{ $t("bookingPickup.voucherNumber") }}</label>
                    <input
                        v-model.trim="model.voucher_no"
                        type="text"
                        class="form-control"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('voucher_no')"
                    >
                        {{ errors.first("voucher_no") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="tw-space-y-1 col-md-12">
                    <label class="required">{{
                        $t("bookingPickup.serviceType")
                    }}</label>
                    <a-select
                        v-model="model.service_type_id"
                        :options="serviceType"
                        class="tw-w-full"
                        :placeholder="$t('bookingPickup.serviceType')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('service_type_id')"
                    >
                        {{ errors.first("service_type_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="tw-space-y-1 col-md-12">
                    <label class="required tw-w-full">{{
                        $t("productPackaging.assignDriver")
                    }}</label>
                    <a-select
                        show-search
                        v-model="model.driver_id"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        :placeholder="$t('driverClearCash.typeToSearch')"
                        :loading="fetch_driver"
                        @search="handleDriverSearch"
                        class="tw-w-full"
                    >
                        <a-select-option
                            v-for="c in driverList"
                            :key="c.driver_id"
                        >
                            {{ c.driver_name }}
                        </a-select-option>
                    </a-select>
                    <div class="tw-text-red-500" v-if="errors.has('driver_id')">
                        {{ errors.first("driver_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label>{{ $t("productPackaging.comment") }}</label>
                    <textarea
                        v-model="model.comment"
                        :placeholder="$t('productPackaging.comment')"
                        rows="3"
                        class="form-control"
                    ></textarea>
                    <div class="validate" v-if="errors.has('comment')">
                        {{ errors.first("comment") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        class="mr-2"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t("cancel") }}
                    </ts-button>
                    <ts-button color="primary" @click.prevent="onSave">{{
                        $t("save")
                    }}</ts-button>
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";
export default {
    name: "confirmModal",
    props: ["record"],
    data() {
        return {
            loading: false,
            errors: new Errors(),
            serviceType: [],
            driverList: [],
            fetch_driver: false,
            model: {
                service_type_id: null,
                voucher_no: null,
                driver_id: null,
                comment: ""
            }
        };
    },
    computed: {
        ...mapState("inventory/productPackaging", ["formModels"])
        // serviceType() {
        //     if (!this.formModels.serviceType) return [];
        //     return this.formModels.serviceType.map(el => ({
        //         value: el.service_type_id,
        //         label: el.service_type_name_en
        //     }));
        // }
    },
    created() {
        this.fetchFormView({
            params: {
                fnName: "serviceType"
            },
            index: "serviceType"
        });

        this.model.service_type_id = this.record.service_type_id;
    },
    methods: {
        ...mapActions("inventory/productPackaging", [
            "getFormViewData",
            "convertBooking"
        ]),
        handleDriverSearch: debounce(function(value) {
            this.fetch_driver = true;
            this.getFormViewData({
                params: {
                    fnName: "driverProfile",
                    search_driver: value
                }
            })
                .then(response => {
                    this.driverList = response.data.driverProfile;
                })
                .finally(() => (this.fetch_driver = false));
        }, 500),
        onSave() {
            this.errors = new Errors();
            this.loading = true;
            this.$store
                .dispatch("inventory/productPackaging/confirm", {
                    id: this.record.packaging_id,
                    data: this.model
                })
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchFormView({ params, index }) {
            this.loading = true;
            this.getFormViewData({ params: params, index: index })
                .then(response => {
                    this.serviceType = response.data.serviceType.map(el => ({
                        value: el.service_type_id,
                        label: el.service_type_name_en
                    }));
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
